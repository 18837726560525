import {useQuery} from 'react-query';
import Frame from './Frame';
import {useChainId, useEthereum, useProvider} from './hooks';
import CommentSpacer from './CommentSpacer';
import MintingArea from './MintingArea';
import PageHeader from './PageHeader';
import LinkIconsRow from './LinkIconsRow';
import collections from '../lib/collections';
import ClaimingArea from './ClaimingArea';
import {CodeMakesArt__factory, Quasars__factory} from '../typechain';
import {formatEther} from '@ethersproject/units';
import {CODE_MAKES_ART_ADDRESS, QUASARS_ADDRESS} from '../lib/contracts';
import collection from '../lib/quasars/collection';
import QuasarsMintingArea from './QuasarsMintingArea';
import {useEffect, useState} from 'react';
import {useWalletProvider} from './useWeb3';

export default function QuasarsMain() {
  const {chainId, infuraProvider} = useWalletProvider();
  const [now, setNow] = useState(Date.now());
  useEffect(() => {
    const i = setInterval(() => setNow(Date.now()), 2000);
    return () => clearInterval(i);
  }, []);

  const {data: totalSupply} = useQuery(['supply', chainId], async () =>
    Quasars__factory.connect(QUASARS_ADDRESS[chainId]!, infuraProvider)
      .totalSupply()
      .then((s) => s.toNumber()),
  );

  const soldOut = typeof totalSupply == 'number' && totalSupply >= 10000;

  return (
    <div>
      <PageHeader title={collection.name} image={collection.image} description={collection.description} />
      <div className="content">
        <div className="about">
          <h1>{collection.name}</h1>
          {collection.description.split('\n').map((text, idx) => (
            <p key={idx}>{text}</p>
          ))}
          <LinkIconsRow opensea={collection.opensea} contractAddress={QUASARS_ADDRESS[chainId]!} />
          <div className="line" />
          <p>
            <span title="Mint 1 for free, only pay for gas. When minted in batch, the price is 0.01 per token, up to 20 at a time.">
              <strong>Price</strong>&nbsp;FREE<sup>*</sup>
            </span>
            <CommentSpacer />
            <strong>Minted</strong>&nbsp;
            <span className="supply">
              {totalSupply || '…'}&nbsp;<span className="comment">/</span>&nbsp;
              {collection.max}
            </span>
            <CommentSpacer />
            <strong>License</strong>&nbsp;{collection.license}
          </p>
        </div>
        <Frame
          preview={<collection.Preview animated hash={'0x' + now.toString(16).split('').reverse().join('')} />}
          title="Displaying a random seed"
          description={collection.name}
          aux={<QuasarsMintingArea soldOut={soldOut} />}
          cellar={
            <div style={{opacity: 0.7, fontSize: 10}}>
              <p>
                * Mint 1 for free, only pay for gas. When minted in batch, the price is 0.01Ξ per token, up to 20 at a
                time.
              </p>
              <p>
                <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 308.634 308.634" width={12} height={12}>
                  <path
                    d="M268.746,32.551V18.003h27.875c4.971,0,9-4.029,9-9s-4.029-9-9-9h-36.663c-0.141-0.003-0.283-0.003-0.424,0h-11.351
	c-0.092-0.002-0.185-0.004-0.277-0.002c-4.929,0.036-8.902,4.026-8.935,8.938c0,0.021,0,0.043,0,0.064c0,0.033,0,0.065,0.001,0.099
	l0.486,66.574c0.007,0.889,0.145,1.772,0.41,2.621l41.714,133.385c1.631,5.217,0.53,10.484-3.271,15.656
	c-6.431,8.749-18.907,14.858-30.34,14.858h-33.283V9.001c0-4.971-4.029-9-9-9H32.485c-4.971,0-9,4.029-9,9v281.634H12.013
	c-4.971,0-9,4.029-9,9s4.029,9,9,9h214.148c4.971,0,9-4.029,9-9s-4.029-9-9-9h-11.474v-30.438h33.283
	c17.063,0,35.083-8.92,44.843-22.197c7.174-9.759,9.286-21.012,5.948-31.689L257.449,74.203l-0.242-33.015
	c0.806,0.236,1.657,0.363,2.539,0.363C264.717,41.551,268.746,37.522,268.746,32.551z M196.688,290.634H41.485V18.001h155.203
	V290.634z M70.521,111.064h97.131c4.971,0,9-4.029,9-9V53.371c0-4.971-4.029-9-9-9H70.521c-4.971,0-9,4.029-9,9v48.693
	C61.521,107.035,65.551,111.064,70.521,111.064z M79.521,62.371h79.131v30.693H79.521V62.371z M126.211,142.507
	c-1.704-2.207-4.335-3.499-7.123-3.499c-0.003,0-0.006,0-0.009,0c-2.79,0.002-5.422,1.3-7.124,3.512
	c-1.115,1.449-2.34,3.013-3.641,4.672c-12.242,15.625-30.744,39.237-30.744,59.982c0,22.893,18.625,41.518,41.518,41.518
	s41.518-18.625,41.518-41.518c0-20.834-18.577-44.497-30.869-60.154C128.483,145.425,127.299,143.916,126.211,142.507z
	 M119.088,230.692c-12.968,0-23.518-10.55-23.518-23.518c0-12.999,13.477-31.571,23.525-44.54
	c10.078,12.992,23.51,31.52,23.51,44.54C142.605,220.142,132.055,230.692,119.088,230.692z"
                    fill="white"
                  />
                </svg>{' '}
                The contract has been optimized for low gas consumption to make the project more accessible to wider
                Ethereum community. The gas fees should be less than 0.003Ξ per mint.
              </p>
            </div>
          }
        />
      </div>
      <style jsx>{`
        .content {
          display: flex;
          align-items: flex-start;
          margin: 100px auto;
          max-width: 1120px;
        }
        .content > :global(*):not(:last-child) {
          margin-right: 52px;
        }
        .content > :global(*) {
          flex: 1;
        }
        @media (max-width: 940px) {
          .content {
            margin: 60px auto;
            max-width: 680px;
            flex-direction: column;
          }
          .content > :global(*):not(:last-child) {
            margin-bottom: 52px;
          }
          .content .frame {
            width: 100%;
          }
        }
        .about > *:not(:last-child) {
          margin-bottom: 24px;
        }
        h1 {
          font-weight: 900;
          font-size: 36px;
          line-height: 44px;
        }
        p {
          margin: 0;
          font-size: 14px;
          line-height: 150%;
          font-weight: 400;
        }
        strong {
          font-weight: 600;
        }
        .comment {
          color: var(--comment-color);
        }
        .line {
          margin: 24px 0px;
          height: 1px;
          background: #c4c4c4;
        }
      `}</style>
    </div>
  );
}
