import {EtherscanIcon, OpenSeaIcon, TwitterIcon} from '../lib/icons';
import PinkLink from '../components/PinkLink';
import CommentSpacer from '../components/CommentSpacer';

export default function LinkIconsRow({opensea, contractAddress}: {opensea: string; contractAddress?: string}) {
  return (
    <div className="social">
      <PinkLink href="https://twitter.com/codemakesart">
        <TwitterIcon />
      </PinkLink>
      <CommentSpacer text="·" />
      <PinkLink href={`https://etherscan.io/address/${contractAddress}`}>
        <EtherscanIcon />
      </PinkLink>
      <CommentSpacer text="·" />
      <PinkLink href={opensea}>
        <OpenSeaIcon />
      </PinkLink>

      <style jsx>{`
        .social {
          display: flex;
          align-items: center;
        }
      `}</style>
    </div>
  );
}
