import {useEffect, useState} from 'react';
import {Web3Provider, BaseProvider} from '@ethersproject/providers';
import {formatEther, parseEther} from '@ethersproject/units';
import {trackGoal} from 'fathom-client';
import ButtonLink from './ButtonLink';
import PinkLink from './PinkLink';
import {useChainId, useConnect, useEthereum, useProvider, useWalletAddress} from './hooks';
import {useQueryClient} from 'react-query';
import {CodeMakesArt__factory, Quasars__factory} from '../typechain';
import {CODE_MAKES_ART_ADDRESS, QUASARS_ADDRESS} from '../lib/contracts';
import {BigNumber} from '@ethersproject/bignumber';
import collections from '../lib/collections';
import DropState from '../lib/DropState';
import collection from '../lib/quasars/collection';
import {useWalletProvider} from './useWeb3';

type Drop = {
  name: string;
  description: string;
  artistName: string;
  artistUrl: string;
  price: BigNumber;
  minted: BigNumber;
  maxSupply: BigNumber;
  maxPerMint: BigNumber;
  state: number;
};

export default function QuasarsMintingArea({soldOut}: {soldOut: boolean}) {
  const {chainId, address, connect, web3Provider} = useWalletProvider();
  const client = useQueryClient();

  const price = parseEther(collection.price);
  const maxPerMint = collection.maxPerMint;

  const minting = useMinting(web3Provider, chainId, price, maxPerMint, {
    onDone: () => {
      client.invalidateQueries();
    },
  });

  if (!address && !soldOut) {
    return <ButtonLink border="0" bgcolor="var(--hover-color)" onClick={connect} text="Connect a wallet to mint" />;
  }

  let content;

  if (soldOut) {
    content = (
      <MintText small>
        <ProgressSlashes seed="//\\/" />
        &nbsp;Sold out.{' '}
        <PinkLink inverse href={collection.opensea}>
          View on OpenSea
        </PinkLink>
        &nbsp;
        <ProgressSlashes seed="\//\\" />
      </MintText>
    );
  } else if (minting.busy) {
    content = (
      <MintText small>
        <ProgressSlashes animated seed="//\\/" />
        &nbsp;generating&nbsp;your&nbsp;{collection.name}&nbsp;
        <ProgressSlashes animated seed="\//\\" />
      </MintText>
    );
  } else if (minting.status === 'error') {
    content = (
      <MintText small>
        <ProgressSlashes seed="×" />
        &nbsp;
        {minting.error?.substr(0, 100) || 'Error: something went wrong'}
        &nbsp;
        <ProgressSlashes seed="×" />
      </MintText>
    );
  } else if (minting.status === 'success') {
    content = (
      <MintText small>
        <ProgressSlashes seed="~" />
        &nbsp;Success!&nbsp;
        <ProgressSlashes seed="~" />
      </MintText>
    );
  } else {
    content = (
      <>
        <Button disabled={minting.busy} onClick={minting.decrement}>
          –
        </Button>
        <Button disabled={minting.busy} onClick={minting.increment}>
          +
        </Button>
        <MintText>
          {minting.count}&nbsp;for&nbsp;
          {minting.count === 1 ? 'FREE' : leftStripZero(price.mul(minting.count))}
        </MintText>
        <Button disabled={minting.busy} onClick={minting.mint}>
          Mint
        </Button>
      </>
    );
  }
  return (
    <div className="container" onClick={minting.dismissError}>
      {content}
      <style jsx>{`
        .container {
          background-color: var(--background-reverse);
          display: flex;
          padding: 15px;
          align-items: center;
          min-width: 0px;
          cursor: ${minting.status === 'error' ? 'pointer' : undefined};
        }
        .container > :global(*):not(:last-child) {
          margin-right: 3px;
        }
      `}</style>
    </div>
  );
}

function ProgressSlashes({seed, animated}: {seed: string; animated?: boolean}) {
  const [shift, setShift] = useState(0);

  useEffect(() => {
    if (!animated) {
      return;
    }
    const interval = setInterval(() => {
      setShift((s) => (s + 1) % seed.length);
    }, 100);
    return () => clearInterval(interval);
  }, [seed, animated]);

  return (
    <span className="progress">
      {(seed + seed).substr(seed.length - shift, seed.length)}
      <style jsx>{`
        .progress {
          color: #757575;
        }
      `}</style>
    </span>
  );
}

function MintText({children, small}: any) {
  return (
    <div className="mint-text">
      {children}
      <style jsx>{`
        .mint-text {
          flex: 1;
          text-align: center;
          color: var(--foreground-reverse);
          font-variant-numeric: tabular-nums;
          font-weight: 500;
          font-size: ${small ? '12' : '17'}px;
          line-height: ${small ? '15' : '21'}px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
    </div>
  );
}

export function Button(props: any) {
  return (
    <>
      <button {...props} />
      <style jsx>{`
        button {
          padding: 0px 10px;
          min-width: 33px;
          height: 33px;
          color: var(--foreground-color);
          background-color: var(--foreground-reverse);
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          vertical-align: text-top;
          border: none;
          cursor: default;
          will-change: transform;
          transition: transform 50ms;
          transition-timing-function: ease-in-out;
        }
        button:hover {
          transform: translateY(-2px);
          border-bottom: 3px solid #ff658a;
          cursor: pointer;
        }
        button:active {
          transform: translateY(-1px);
          border-bottom: 2px solid #ff658a;
        }
      `}</style>
    </>
  );
}

function leftStripZero(n: BigNumber) {
  const s = formatEther(n);
  if (s.startsWith('0.')) {
    return s.slice(1).padEnd(3, '0');
  }

  return s;
}

type MintingStatus = 'idle' | 'minting' | 'waiting' | 'success' | 'error';

export function useMinting(
  provider: BaseProvider | null,
  chainId: number,
  price: BigNumber,
  maxPerMint: number,
  {onDone}: {onDone: () => void},
) {
  const [count, setCount] = useState(1);
  const [status, setStatus] = useState<MintingStatus>('idle');
  const [error, setError] = useState<string>('');

  const increment = () => setCount((c) => Math.min(c + 1, maxPerMint));
  const decrement = () => setCount((c) => Math.max(c - 1, 1));
  const dismissError = () => {
    setStatus('idle');
    setError('');
  };

  useEffect(() => {
    if (status !== 'success') {
      return;
    }
    const timeout = setTimeout(() => setStatus('idle'), 3000);
    return () => clearTimeout(timeout);
  }, [status]);

  const contractAddress = QUASARS_ADDRESS[chainId];

  async function mint() {
    try {
      setError('');

      if (!provider || !(provider instanceof Web3Provider)) {
        throw new Error('Please connect your wallet first');
      }
      if (!contractAddress) {
        throw new Error(`The selected Ethereum chain is not supported (${chainId})`);
      }

      const contract = Quasars__factory.connect(contractAddress, provider.getSigner());
      setStatus('minting');
      const value = price.mul(count);
      const tx = count == 1 ? await contract.mint_One_4d() : await contract.mint_Several_nmW(count, {value});
      setStatus('waiting');
      await tx.wait();
      setStatus('success');
    } catch (error) {
      setError((error as any).error?.message || (error as any).message);
      setStatus('error');
    } finally {
      onDone();
    }
  }

  return {
    count,
    increment,
    decrement,
    mint,
    status,
    busy: status === 'minting' || status === 'waiting',
    dismissError,
    error,
  };
}
