import React from 'react';

type Props = {
  preview: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  aux: React.ReactNode;
  cellar?: React.ReactNode;
  // more
};

export default function Frame({
  preview,
  title,
  description,
  aux,
  cellar,
}: Props) {
  return (
    <div className="frame">
      <div className="preview">{preview}</div>
      <div className="footer">
        <div className="description">
          <div className="description-text title">{title}</div>
          <div className="description-text fade">{description}</div>
        </div>
        {aux}
      </div>
      {cellar && <div className="cellar">{cellar}</div>}
      <style jsx>{`
        @media (max-width: 940px) {
          .frame {
            width: 100%;
          }
        }
        .frame {
          border: 1px solid var(--border-color);
        }
        .preview {
          margin: 14px;

          height: auto;
          filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.2));
        }
        .footer {
          display: flex;
          border-top: 1px solid var(--border-color);
        }
        .footer > :global(*) {
          flex: 1;
        }
        .footer > *:not(:last-child) {
          border-right: solid 1px var(--border-color);
        }
        @media (max-width: 600px) {
          .footer {
            flex-direction: column;
          }
          .footer > *:not(:last-child) {
            border-right: none;
            border-bottom: solid 1px var(--border-color);
          }
        }
        .description {
          padding: 12px;
        }
        .description > *:not(:last-child) {
          margin-bottom: 5px;
        }
        .description-text {
          font-size: 14px;
          line-height: 150%;
        }
        .title {
          font-weight: 500;
        }
        .fade {
          opacity: 0.5;
        }
        .cellar {
          padding: 12px;
          border-top: solid 1px var(--border-color);
        }
      `}</style>
    </div>
  );
}
